<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <h2 class="brand-text text-primary ml-1">Diko</h2>
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> Diko Şifreni Sıfırla</b-card-title>
          <b-card-text class="mb-2" />
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <!-- <b-form-group label="Ad Soyad" label-for="register-username">
                <validation-provider #default="{ errors }" :name="$t('Username')" rules="required">
                  <b-form-input id="register-username" v-model="username" name="register-username"
                    :state="errors.length > 0 ? false : null" placeholder="" />
                  <small class="text-danger">{{ $t(errors[0]).replaceAll("Username", "Ad Soyad")
                    }}</small>
                </validation-provider>
              </b-form-group> -->
              <!-- email -->
              <!-- <b-form-group label="E-Posta" label-for="register-email">
                <validation-provider #default="{ errors }" :name="$t('Email')" rules="required|email">
                  <b-form-input id="register-email" v-model="userEmail" name="register-email"
                    :state="errors.length > 0 ? false : null" placeholder="E-Posta Adresinizi Giriniz..." />
                  <small class="text-danger">{{ $t(errors[0]).replaceAll("Email", "E-Posta")
                    }}</small>
                </validation-provider>
              </b-form-group> -->
              <!-- password -->
              <b-form-group label-for="register-password" label="Şifre">
                <validation-provider #default="{ errors }" :name="$t('Password')" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="register-password" v-model="password" class="form-control-merge"
                      :type="passwordFieldType" :state="errors.length > 0 ? false : null" name="register-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]).replaceAll("Password", "Şifre")
                    }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label-for="register-password-repeat" label="Şifre Tekrar">
                <validation-provider #default="{ errors }" :name="$t('Password') + '' + $t('Repeat')" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="register-password-repeat" v-model="passwordRepeat" class="form-control-merge"
                      :type="passwordFieldType" :state="errors.length > 0 ? false : null" name="register-password-repeat"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]).replaceAll("PasswordRepeat", "Şifre Tekrar")
                    }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group>
                <label>Uzmanlık Alanı</label>
                <b-form-select v-model="field">
                  <b-form-select-option value="">Seçiniz</b-form-select-option>
                  <b-form-select-option value="psychiatry">Psikiyatri</b-form-select-option>
                  <b-form-select-option value="psychology">Psikoloji</b-form-select-option>
                  <b-form-select-option value="counseling">PDR</b-form-select-option>
                  <b-form-select-option value="other">Diğer</b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group>
                <validation-provider #default="{ errors }" :name="$t('Privacy')" rules="required">
                  <b-form-checkbox id="register-privacy-policy" v-model="status" name="checkbox-1">
                    <b-link v-b-modal.modal-center>Kullanıcı Sözleşmesi & Gizlilik Metnini</b-link> Okudum Onaylıyorum
                  </b-form-checkbox>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group> -->
              <b-button variant="primary" block type="submit" @click.prevent="validationForm"> Sıfırla </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <span>Üyeliğiniz Var Mı?</span>
            <b-link :to="{ name: 'auth-login-v2' }">
              <span>&nbsp;Giriş Yapın</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
    <!-- <b-modal id="modal-center" scrollable :title="privacy.privacy.title" ok-only ok-title="Kabul Et" @ok="privacyOk"
      size="lg" style="overflow-x: hidden">
      <b-card-text v-html="privacy.privacy.content" style="width: 100%">
        {{ privacy.privacy.content }}
      </b-card-text>
    </b-modal> -->
  </div>
</template>

<script>
/* eslint-disable global-require */
// import privacy from "../libs/privacy.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BModal,
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    //select
    BFormSelect,
    BFormSelectOption,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: "",
      passwordRepeat: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
    };
  },
  created() {
    //console.log(this.privacy);
    const newMetaOgImg = document.createElement("meta");
    newMetaOgImg.setAttribute("property", "og:image");
    newMetaOgImg.setAttribute(
      "content",
      `http://app.dikotr.com${require("@/assets/share.jpg")}`
    );
    document.head.appendChild(newMetaOgImg);
    const newMetaOgImgType = document.createElement("meta");
    newMetaOgImgType.setAttribute("property", "og:image:type");
    newMetaOgImgType.setAttribute("content", "image/jpeg");
    document.head.appendChild(newMetaOgImgType);
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          if (this.password.length < 8)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Error"),
                text: this.$t("Şifreniz en az 8 karakter olmalıdır."),
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          else if (this.password != this.passwordRepeat)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Error"),
                text: this.$t("Şifreleriniz eşleşmiyor."),
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          else {
            axios
              .post(`/auth/reset`, {
                password: this.password,
                token: decodeURIComponent(this.$route.params.token),
              })
              .then((response) => {
                console.log(response);
                if (response.status == 200) this.$router.push({ name: "auth-login" });
              });
          }

          // useJwt
          //   .register({
          //     name: this.username,
          //     email: this.userEmail,
          //     password: this.password,
          //     field: this.field,
          //     referrer: this.$route.query.referrer,
          //   })
          //   .then(() => {
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: this.$t("Register Success"),
          //         text: this.$t("You can login with the credentials you provided"),
          //         icon: "CheckIcon",
          //         variant: "success",
          //       },
          //     });
          //     this.$router.push("/login");
          //   });
          // console.log(resp);
          // .then(() => {
          //   useJwt.login(
          //     {
          //       email: this.userEmail,
          //       password: this.password,
          //     },
          //     {
          //       onSuccess: () => {
          //         this.$router.push({ name: "home" });
          //       },
          //     }
          //   );
          // console.log(response);
          // const abilityData = { ability: response.data.ability };
          // useJwt.setToken(response.data.accessToken);
          // useJwt.setRefreshToken(response.data.refreshToken);
          // localStorage.setItem("userData", JSON.stringify(abilityData));
          // this.$ability.update(abilityData.ability);
          // this.$router.go();
          // })
          // .catch((error) => {
          // console.log(error.response.data.message)
          // this.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Hata!',
          //       text: error.response.data.message,
          //       icon: 'WarningIcon',
          //       variant: 'danger',
          //     },
          //   },
          //   {
          //     timeout: 2000,
          //   },
          // )
          // this.$refs.registerForm.setErrors(error.response.data.message);
          // });
        }
      });

      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: this.$t("Error"),
      //     text: this.$t("You must accept the terms and conditions"),
      //     icon: "WarningIcon",
      //     variant: "danger",
      //   },
      // });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.modal-body {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 210mm;
  padding: 2rem;
}
</style>
